@font-face {
  font-family: "ModellicaMedium";
  src: local("ModellicaMedium"), local("Modellica-Medium"),
    url("./assets/fonts/BWModelica/BwModelica-Medium.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaMedium2";
  src: local("ModellicaMedium"), local("Modellica-Medium"),
    url("./assets/fonts/BWModelica/BwModelicaSS02-Medium.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModellicaBold";
  src: local("ModellicaBold"), local("Modellica-Bold"),
    url("./assets/fonts/BWModelica/BwModelica-Bold.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaBold2";
  src: local("ModellicaBold2"), local("Modellica-Bold"),
    url("./assets/fonts/BWModelica/BwModelicaSS01-ExtraBold.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaBold3";
  src: local("ModellicaBold3"), local("Modellica-Bold"),
    url("./assets/fonts/BWModelica/BwModelicaSS02-Bold.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaBlack";
  src: local("ModellicaBlack"), local("Modellica-Black"),
    url("./assets/fonts/BWModelica/BwModelica-Black.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  -webkit-text-size-adjust: 100%;
}
* {
  font-variant-numeric: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  font-family: "ModellicaMedium", sans-serif;
}
h1,
h3,
h4,
h5, button {
  font-family: "ModellicaBold", sans-serif;
}
h2 {
  font-family: "ModellicaBold2", sans-serif;
}
h3{
  font-family: "ModellicaBold3", sans-serif;

}
h4{
  font-family: "ModellicaBlack", sans-serif;

}
p {
  font-family: "ModellicaMedium2", sans-serif;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb:active {
  background: #2f2f2f;
}
::-webkit-scrollbar-track {
  background: #100f0f;
}
::-webkit-scrollbar-track:hover {
  background: #100f0f;
}
::-webkit-scrollbar-track:active {
  background: #100f0f;
}
::-webkit-scrollbar-corner {
  background: #100f0f;
}
.ant-select-dropdown-hidden:first-child,
.ant-select-dropdown:first-child {
  max-height: 300px !important;
  overflow: auto !important;
}
.ant-input {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-popover-inner {
  background: #141414e0 !important;
  max-width: 300px !important;
  min-height: 350px;
  border: 1px solid #fdb91399;
  color: #fff !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-spin-dot-item {
  background: #fdb913 !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #999999 !important;
  color: #fff;
}

input:disabled,
input[disabled] {
  border: 1px solid #99999969 !important;
  background-color: #99999969 !important;
  color: transparent !important;
}
input:disabled::placeholder,
input[disabled]::placeholder {
  color: transparent !important;
}
.ant-picker-header {
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active, 
input:-webkit-autofill:visited {
  -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
  box-shadow: 0 0 0 30px #000000 inset !important;
  -webkit-text-fill-color: #fff !important;
} 

 input:-internal-autofill-selected{
  background-color: #000000 !important;
 }
.ant-input-affix-wrapper:hover {
  border-color: #fcb31699 !important;
}

[ant-click-animating-without-extra-node="true"]::after,
.ant-click-animating-node {
 display: none !important;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: transparent !important;
}


.ant-table-filter-dropdown-btns button{
  background-color: #1D7874;
  color: #fff;
  box-shadow: none;
  border: 1px solid #1D7874;
  font-size: 12px;
  padding: 0 10px;
}
.ant-table-filter-dropdown-btns button:hover{
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #1D7874;
  color: #1D7874;
}
.ant-table-filter-dropdown-btns .ant-btn-link {
  background-color: transparent;
  color: #1D7874;
}

.ant-table-filter-dropdown-btns .ant-btn-link:hover{
  background-color: #1D7874;
  color: #fff;
}

button:disabled, button[disabled]{
  background-color: rgba(238, 238, 238, 0.754) !important;
  border: none !important;
  color: #999999 !important;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title span{
  color: #1D7874;
font-size: 12px;
}

.ant-checkbox-checked,
.ant-checkbox-inner {
  background: transparent !important;
  border-color: #1D7874 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1D7874 !important;
}

.search_button{
  background-color: #1D7874;
  border-color: #1D7874;
}

.search_button:hover,.search_button:active {
  background-color: transparent;
  border-color: #1D7874;
  color: #1D7874;
}
.search_input:focus, .search_input:active{
  box-shadow: none;
  border-color:#1D7874;
}
.reset_button{
  color:#1D7874;
  border-color:#1D7874;
}
.reset_button:hover, .reset_button:active{
  background-color:#1D7874;
  border-color:#1D7874;
  color: #fff;
}